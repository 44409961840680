import { defineStore } from 'pinia'

export const useCategoryStore = defineStore({
  id: 'categories',
  state: () => ({
		list: [], 
    flat_list: [],
    inherited_attributes: [],
		current: '',
		mode: '',
    attributes: [],
		categories_attributes: [],
		categories_accessories: [],
		categories_service_types: [],
    attribute_types: [],
  }),
  getters: {
  }, 
  actions: {
  }
})
