import Api from "@/services/Api";

export default {
    async getProcesses(){
        var list = []
        await Api(true).post("processes/list", {})
        .then(res => {
                list = res.data
            })
            return list
    },
    async getProcessesSteps(guid){
        var steps = []
        var disputes = []

        await Api(true).post("processes/steps", {process_guid: guid})
        .then(res => {

            steps = res.data['steps']
            disputes = res.data['disputes']

            // Add disputes
            steps = [...steps.map(s => {
                disputes.map(d => {
                    if(d.step_id == s.guid){
                        if(!s.disputes) {s.disputes = []}
                        s.disputes.push(d)
                    }
                })
                return s
            })]
        })
        return steps
    },
}