import { defineStore } from 'pinia'

// const defaultState  = {
//     items: [],
//     status: 'empty',
//     is_new: false
// }

export const useKrudhStore = defineStore({
  id: 'krudh_communication',

//   state: () => ({ ...defaultState }),

  state: () => ({
    response: [],
    entity: '',
    is_new: false
  }),

  getters: {
  }, 
  actions: {
    //  reset(keys) {
    //   Object.assign(this, keys?.length
    //     ? defineStore(defaultState, keys)
    //     : defaultState // if no keys provided, reset all
    //   );
    // }

    // reset() {
    //   Object.assign(this, getDefaultState);
    // }

    // resetState ({ commit }) {
    //     commit('resetTheState')
    // },
  },
  mutations: {
    // resetTheState (state) {
    //     // Merge rather than replace so we don't lose observers
    //     // https://github.com/vuejs/vuex/issues/1118
    //     Object.assign(state, getDefaultState())
    // }
  }
})