<template>
  <div>
    <div class="is-flex mb-3">
      <div class="border rounded border-slate-100 hover:bg-slate-100  ">
        <a href="" v-if="state == 0" @click.prevent="setCurrent(null)" class="px-2 py-1">
          <i class="fa fa-plus"></i>
        </a>
        <a href="" v-if="state != 0" @click.prevent="state = 0" class="px-2 py-1">
          <i class="fa fa-times"></i>
        </a>
      </div>
    </div>
    <DaForm v-if="current_form && state != 0" @submitted="submitEntity" :form="current_form"
      style="background: #f3f3f3; padding: 10px;">
    </DaForm>
    <!-- <i class="fa fa-spinner fa-spin" v-if="form_loading && state != 0"></i> -->
    <div class="mt-2">
      <table class="table is-striped is-fullwidth is-hoverable is-narrow">
        <thead>
          <tr>
            <th :width="getColumnStyle(c.name)" v-for="(c, index) in current_form.columns" :key="index">{{ c.label }}
            </th>
            <th width="80px" class="has-text-center">
              <i class="fa fa-gear"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(gd, index) in grid_data" :key="index">
            <td v-for="(c, index) in current_form.columns" :key="index">
              <span v-if="c.name == 'icon'">
                <i :class="[gd[c.name]]"></i>
              </span>
              <span v-else>
                <span v-if="c.name == 'subdivide_documents'" class="text-xs">
                  <!-- Checkbox values -->
                  <i v-if="gd[c.name]" class="fa-solid fa-check"></i>
                  <i v-else class="fa-solid fa-xmark"></i>
                </span>
                <span v-else>
                  {{ gd[c.name + '_name'] ?? gd[c.name] }}
                </span>
              </span>
            </td>
            <td class="p-2">
              <a href="" @click.prevent="setCurrent(gd)">
                <i class="fa fa-pencil"></i>
              </a>
              <a href="" @click.prevent="deleteCurrent(gd)" class="ml-2">
                <i class="fa fa-trash text-red-800"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DaForm from "@/components/daform/index.vue";
import Api from "@/services/Api";
import { useKrudhStore } from "@/stores/krudh_communication";
import { storeToRefs } from "pinia";
export default {
  props: ["columns", "fields", "entity", "where", "defaults", "sort", "joins", "join_select", "allow_delete"],
  components: { DaForm },
  data() {
    return {
      form: null,
      grid_data: [],
      state: 0, // 0: closed, 1: opened, 2: edit
      form_loading: true,
      current: null,
      communication_data: storeToRefs(useKrudhStore()),
    }
  },
  methods: {
    setCurrent(c) {
      this.state = 0
      setTimeout(() => {
        this.current = c
        this.state = 2
      }, 100)
    },

    deleteCurrent(f) {
      if (!confirm("A jeni i sigurt qe doni te fshini kete fushe?")) return 
      Api(true).post('/krudh/delete/' + this.entity, f).then(response => {
        console.log(response.status)
        if (response.status == 200) {
          this.grid_data.map((gd, index) => {
            if (gd.guid == f.guid) {
              this.grid_data.splice(index, 1)
            }
          })
          this.$toast.success("Fshirja u krye me sukses.")
        }
      })
    },
    submitEntity(f) {
      Api(true).post('/krudh/post/' + this.entity, {
        fields: this.current_form.fields_plain,
        form: f,
        current: this.current,
        where: this.where,
        defaults: this.defaults
      }).then(response => {
        if (response.data) {
          if (response.data.length > 0) {
            var is_new = false
            if (this.current) {
              this.grid_data.map((gd, index) => {
                if (gd.guid == this.current.guid) {
                  this.grid_data[index] = response.data[0]
                }
              })
              this.$toast.success("Ndryshimi u ruajt me sukses.")
            } else {
              is_new = true
              this.grid_data.push(response.data[0])
              
              // Close modal when is new - Temporary solution
              this.$emit('close')

              if(this.entity == 'processes')
                this.$toast.success("Procesi " + response.data[0].name +" u krijua me sukses.");
              if(this.entity == 'process_steps')
                this.$toast.success("Hapi " + response.data[0].name +" u krijua me sukses.");
            }

            this.state = 0
            
            // Set store variable to emmit results 
            this.communication_data.response = response.data
            this.communication_data.entity = this.entity
            this.communication_data.is_new = is_new

          }
        }
      })
    },
    getColumnStyle(c) {
      let style = "auto";
      if (this.columns) {
        this.columns.map(col => {
          var pieces = col.split(':')
          if (pieces[0] == c) style = pieces[1]
        })
      }
      return style
    }
  },
  computed: {
    current_form: {
      get() {
        var fields_list = []
        var fields_plain = []
        var columns_list = []

        this.fields.map(f => {
          if (f) {
            var fg = f.split(',')
            var frow = []
            fg.map(ff => {
              var pieces = ff.split(':')
              var name = pieces[0].split('*').join('').split('!').join('')
              frow.push(
                {
                  value: this.current && this.current[name] ? this.current[name] : (['current_user'].includes(pieces[1]) ? pieces[1] : ''),
                  name: name,
                  label: pieces[1],
                  type: name == 'icon' ? 'icon-field' 
                  : pieces.length == 3 ? pieces[2] 
                  : 'text-field',
                  style: 'flex: ' + (name == 'icon' ? '0 0 70px' : '1') + (['current_user'].includes(pieces[1]) ? ';display:none;' : ''),// 'min-width: ' + ((100 / fg.length) - (fg.length > 1 ? 1 : 0)) + '%',
                  required: pieces[0].includes('*'),
                  required_text: 'Kjo fushe eshte e detyrueshme'
                }
              )
              columns_list.push({ name, 'label': pieces.length > 3 ? pieces[2] : pieces[1] })
              fields_plain.push(name)
            })
            fields_list.push(frow)
          }
        })
        return {
          name: this.current ? "Modifiko " : "Krijo ",
          submit: {
            label: this.current ? "Modifiko" : "Krijo",
            action: 'submitEntity'
          },
          fields: fields_list,
          fields_plain: fields_plain,
          columns: columns_list,

        }
      },
      set() {
        this.form_loading = false
      }

    },

  },
  created() {
    Api(true).post('/krudh/get/' + this.entity, {
      where: this.where,
      sort: this.sort,
      joins: this.joins,
      join_select: this.join_select

    })
      .then(response => {
        this.grid_data = response.data
      })
  },
}
</script>

<style>

</style>
